.grid-container {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 50px;
  grid-template-columns: auto auto;
  padding: 10px;
}

.grid-item {
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  text-align: center;
}

span.positive {
  color: green;
}

span.negative {
  color: red;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.cal-change {
  font-size: 80%;
  font-weight: bold;
}
